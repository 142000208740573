// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$custom-primary: (
  900: #1C3243, // Custom primary color
  contrast: (
    50 : $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$neglect-x-primary: mat-palette($custom-primary, 900);
$neglect-x-accent: mat-palette($mat-grey, 500, A100, A400);

// $mat-grey, 900
//  - #212121
//  - light #484848
//  - dark #000000
// $mat-500, 900 => seems no A range....
//  -  #9e9e9e
//  - light #838383
//  - dark #707070

// The warn palette is optional (defaults to red).
$neglect-x-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$neglect-x-theme: mat-light-theme(
  (
    color: (
      primary: $neglect-x-primary,
      accent: $neglect-x-accent,
      warn: $neglect-x-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($neglect-x-theme);

/* You can add global styles to this file, and also import other style files */
.mat-display-4,
.mat-display-3,
.mat-display-2,
.mat-display-1,
.mat-display-1.page-title,
.mat-display-1.page-content-title,
.mat-headline,
.mat-title,
.mat-subheading-2,
.mat-subheading-1 {
  margin: 0px;
}
