/* You can add global styles to this file, and also import other style files */

@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fbfbfb;
}

a {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

*:focus,
input:focus,
textarea:focus {
  outline: none;
}

.bold {
  font-weight: bold;
}

.error {
  color: #f44336;
}

.clickable {
  cursor: pointer;
}

.page-background-block {
  height: 140px;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-color: #dddddd;
}

.page-header {
  padding: 0px 24px;
  height: 100px;
}

.page-content {
  margin: 0px 24px 114px 24px;
}

mat-card.page-content {
  margin: 0px 24px 114px 24px;

  .page-content-header {
    margin-bottom: 16px;
  }

  .page-content-title {
    font-weight: bold;
  }

  // .page-section {}

  .page-section--without-border {
    padding: 0px;
  }

  .page-section-header {
    min-height: 36px;
  }

  .page-section-title {
    font-weight: 550;
    color: #1c3243;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

mat-dialog-container.mat-dialog-container {
  overflow: visible;
}

.dialog {
  position: relative;
  overflow: visible;
}

button.dialog-close {
  position: absolute;
  top: -24px;
  right: -24px;
  transform: translate(50%, -50%);
}

.dialog-content {
  max-height: 80vh;
  overflow-y: auto;
  padding: 4px;
}

mat-form-field {
  width: 0px;
}

mat-form-field.time {
  .mat-form-field-infix {
    padding-bottom: 5px;
  }
}

.ngx-material-timepicker-toggle {
  padding: 0 !important;
  align-items: end !important;
  align-self: self-end !important;
  svg {
    margin: 0;
    height: 10% !important;
    width: 1em;
  }
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker-overlay {
  --body-background-color: #fff;
  --primary-font-family: "Roboto", sans-serif;
  --button-color: rgb(0, 0, 0);
  --dial-active-color: #fff;
  --dial-inactive-color: rgba(255, 255, 255, 0.5);
  --dial-background-color: rgb(0, 0, 0);
  --dial-editable-active-color: rgb(0, 0, 0);
  --dial-editable-background-color: #fff;
  --clock-face-time-active-color: #fff;
  --clock-face-time-inactive-color: #6c6c6c;
  --clock-face-inner-time-inactive-color: #929292;
  --clock-face-time-disabled-color: #c5c5c5;
  --clock-face-background-color: #f0f0f0;
  --clock-hand-color: rgb(0, 0, 0);
}

.autocomplete-label {
  font-size: 10px;
  font-weight: bold;
}

.mat-snack-bar-container.snackbar-custom-info-class {
  background: rgba(250, 250, 250, 1);
  color: #808080;
  max-width: 45vw;
  word-break: break-word;
  white-space: pre-wrap;
}

.mat-snack-bar-container.snackbar-custom-warning-class {
  background: rgba(255, 204, 128, 1);
  color: black;
  max-width: 45vw;
  word-break: break-word;
  white-space: pre-wrap;
}
.mat-snack-bar-container.snackbar-custom-error-class {
  background: rgba(229, 115, 115, 1);
  color: white;
  max-width: 45vw;
  word-break: break-word;
  white-space: pre-wrap;
}

.ngx-file-drop-zone {
  border: 1px solid #dcdcdc;
  border-radius: 30px;
}

.ngx-file-content {
  color: #000000;
  height: 55px; // mat-form-field height - 1px
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
    width: Fill (636px)px;
  }
  .mat-row,
  .mat-header-row {
    display: table-row;
    height: 36px;
    padding: 0px 12px 0px 12px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    border: 0px 0px 1px 0px;
  }
  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

.mat-row.clickable:hover {
  background-color: #f5f5f5;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 4px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 4px !important;
}

mat-checkbox.technical-report-field {
  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
  }
}

mat-form-field.technical-report-field.date {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  height: 20px;

  .mat-form-field-infix {
    padding: 0px 0px 6px 0px;
    border-top: 0px;
  }
}

mat-form-field.technical-report-field.select {
  width: 250px;
  font-size: 14px;
  font-weight: bold;
  height: 20px;

  .mat-form-field-infix {
    padding: 0px 0px 6px 0px;
    border-top: 0px;
  }
}

mat-form-field.technical-report-field.textarea {
  width: 100%;
}

.reason {
  line-height: 24px;
}

mat-radio-button.mat-radio-button.mat-accent.error.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}

mat-radio-button.mat-radio-button.mat-accent.error .mat-radio-inner-circle {
  color: #f44336;
  background-color: #f44336;
}

mat-radio-button.mat-radio-button.mat-accent.error .mat-radio-ripple .mat-ripple-element {
  background-color: #f44336;
}

.mat-input-element[type="time"]::after {
  white-space: normal !important;
}

button.quick-edit {
  width: 20px;
  height: 20px;
  line-height: unset;
  margin-bottom: 8px;

  span.mat-button-wrapper {
    padding-bottom: 4px;
    border-bottom: 1px solid #949494;

    mat-icon.mat-icon.material-icons {
      width: 20px;
      font-size: 18px;
      color: #757575;
    }
  }
}

.generic-search-field {
  background-color: white;
  border-radius: 88px;
  height: 44px;
  max-width: 400px;
  padding: 0px 22px;
  border: 1px solid #dcdcdc;

  input {
    background: none;
    border: none;
    font-size: 16px;
  }
}

.mat-menu-content {
  .mat-title {
    padding-left: 16px;
  }
  .mat-subheading-2 {
    padding-left: 16px;
  }
}

.isHidden {
  visibility: hidden;
}

.y-overflow-50vh {
  overflow-y: scroll;
  height: 65vh;
}

.divider-bottom {
  border-bottom: lightgray solid 3px;
}

.divider-top {
  border-top: lightgray solid 3px;
}

.thin-divider-top {
  border-top: lightgray 0.5px solid;
  padding-top: 10px;
}

.thin-divider-bottom {
  border-bottom: lightgray 0.5px solid;
  padding-bottom: 10px;
}

.indent {
  padding-left: 10px;
}

.padding-right-textarea {
  padding-right: 25px;
}
.pb-non-texterea {
  padding-bottom: 1.34375em;
}
.pb-10 {
  padding-bottom: 10px;
}
.text-align-center {
  text-align: center;
}

.mat-input-element:disabled {
  color: #656565;
}

.mat-select-disabled .mat-select-value {
  color: #656565;
}

// Custom CSS
.row-spacing {
  margin-top: 40px;
}
.card-height {
  min-height: 100px;
}

.min-width-150 {
  min-width: 150px !important;
}

.version-label {
  font-size: 12px;
  color: #838383;
  font-weight: 400;
}

.mat-drawer-container {
  background: linear-gradient(180deg, #fff 5%, rgba(223, 230, 241, 0.75) 30%);
}

.page-background-block {
  background-color: unset;
}

// .mat-card.page-content {
//   background: transparent; /* Makes the background transparent */
//   border: none; /* Removes any border */
//   box-shadow: none; /* Removes shadow if there is any */
// }

.mat-table {
  background: transparent;
}

.mat-paginator {
  background: transparent;
}

.mat-header-row .mat-header-cell {
  border-radius: 0; /* top-left, top-right, bottom-right, bottom-left */
  background: #fff;
  margin-bottom: 10px;
}

/* Targeting the first mat-header-cell in each mat-header-row */
.mat-header-row .mat-header-cell:first-child {
  border-radius: 10px 0 0 10px; /* top-left, top-right, bottom-right, bottom-left */
  background: #fff;
}

/* Targeting the last mat-header-cell in each mat-header-row */
.mat-header-row .mat-header-cell:last-child {
  border-radius: 0 10px 10px 0; /* top-left, top-right, bottom-right, bottom-left */
  background: #fff;
}

.mat-header-row {
  margin: 10px;
}

td.mat-cell {
  border-bottom-style: none;
}

th.mat-header-cell {
  border-bottom-style: none;
}

.navigation-button {
  background: var(--Primary-75, rgba(28, 50, 67, 0.75));
  color: #fff;
}

.sub-title {
  color: var(--Primary, #1c3243);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}

.sub-content {
  color: var(--Primary, #1c3243);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.4px;
}

.entity {
  .mat-sort-header-arrow {
    color: #ffffff;
  }
}

.entity .mat-header-row .mat-header-cell {
  border-radius: 0; /* top-left, top-right, bottom-right, bottom-left */
  background: #699ecb;
  color: white;
}

/* Targeting the first mat-header-cell in each mat-header-row */
.entity .mat-header-row .mat-header-cell:first-child {
  border-radius: 10px 0 0 10px; /* top-left, top-right, bottom-right, bottom-left */
  background: #699ecb;
}

/* Targeting the last mat-header-cell in each mat-header-row */
.entity .mat-header-row .mat-header-cell:last-child {
  border-radius: 0 10px 10px 0; /* top-left, top-right, bottom-right, bottom-left */
  background: #699ecb;
}

.mat-card-title {
  color: var(--mat-card-title-color, #1f3242) !important;
  margin-bottom: 10px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 550;
  line-height: 24px;
}

.card-subtitle {
  color: var(--mat-card-subtitle-color, #1f3242) !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding:10px 0;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: var(--Primary, #1c3243);
}

.mat-stroked-button{
  color: var(--Primary, #1c3243);
}

.delete-button {
  background-color: #c62828 !important; 
  color: white;
  border-color: transparent !important;
}

.delete-label {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;  
}

.list {
  margin-bottom: 10px;
  height: 100%;
}

.list-item {
  border-left: 4px solid #1C3243;
  height: 62px;
  padding-left: 10px;
}

// .list-item:hover {
//   background-color: #f0f0f0;
//   cursor: pointer;
// }

.list-item-title {
  color: var(--Primary, #1c3243);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.list-item-subtitle {
  color: var(--Primary-75, rgba(28, 50, 67, 0.75));
}

.page-title {
  color: var(--Primary, #1c3243);
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
}

.bottom-border {
  border-bottom: 1px solid #DCDCDC;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  display: inline-flex !important;
  padding: 4px 6px !important;
  border-radius: 16px !important;
  align-items: center !important;
  cursor: default !important;
  min-height: 28px !important;
  font-size: 12px !important;
  height: 1px !important;
}


th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 0;
  border-bottom-width: unset !important;
  border-bottom-style: unset !important;
}

.mat-row:not(:last-child) {
  border-bottom: 1px solid #1c32435d;
}

.filter-select {
  width: 20ch;
  border-left: 1px solid #dcdcdc;
  padding-left: 6px;
}

.tooltip-multi-line {
  white-space: pre-wrap;
}